import { useAuth0 } from "@auth0/auth0-react";
import { captureException, setUser, withScope } from "@sentry/nextjs";
import { useRouter } from "next/navigation";
import { useEffect, useState } from "react";

import { initialsFromName } from "services/users";

const useAuth = () => {
  const router = useRouter();

  const { getAccessTokenSilently, user, logout } = useAuth0();
  const [error, setError] = useState<any>(null);

  const organisationId =
    user && user[`${process.env.NEXT_PUBLIC_AUTH0_NAMESPACE}/organisation_id`];

  const userId =
    user && user[`${process.env.NEXT_PUBLIC_AUTH0_NAMESPACE}/user_id`];

  const roles: string[] | null =
    user && user[`${process.env.NEXT_PUBLIC_AUTH0_NAMESPACE}/user_role`];

  const userInitials = initialsFromName(user?.name);

  const getAccessToken = async () => {
    try {
      const token = await getAccessTokenSilently();
      return token;
    } catch (err) {
      setError(err);
      withScope((scope) => {
        if (err.message === "Login required") {
          scope.setLevel("warning");
        }

        captureException(err);
      });
      return "";
    }
  };

  useEffect(() => {
    if (error) {
      router.push("/login");
    }
  }, [error]);

  useEffect(() => {
    if (user) {
      setUser({
        id: userId,
        email: user.email,
        name: user.name,
        organisation_id: organisationId,
        roles,
      });
    }
  }, [user]);

  const signOut = () => {
    logout({
      logoutParams: {
        returnTo: `${process.env.NEXT_PUBLIC_NEIGHBOURHOOD_HOST}/login`,
      },
    });
  };

  return {
    signOut,
    getAccessToken,
    organisationId,
    userId,
    email: user?.email,
    name: user?.name,
    roles,
    userInitials,
  };
};

export default useAuth;
