import { useEffect } from "react";
import { hotjar } from "react-hotjar";

function Hotjar() {
  useEffect(() => {
    if (
      process.env.NEXT_PUBLIC_HOTJAR_SITE_ID &&
      process.env.NEXT_PUBLIC_HOTJAR_SNIPPET_VERSION
    ) {
      hotjar.initialize({
        id: Number(process.env.NEXT_PUBLIC_HOTJAR_SITE_ID),
        sv: Number(process.env.NEXT_PUBLIC_HOTJAR_SNIPPET_VERSION),
      });
    }
  }, []);

  return null;
}

export default Hotjar;
