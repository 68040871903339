import { usePathname, useSearchParams } from "next/navigation";
import {
  PropsWithChildren,
  createContext,
  useEffect,
  useMemo,
  useState,
} from "react";

interface Page {
  pathname: string;
  returnTo: string;
}

interface ReturnContextValue {
  returnTo?: Page;
  previousPages?: Page[];
}

const ReturnContext = createContext<ReturnContextValue>({});

export default ReturnContext;

export function ReturnContextProvider({ children }: PropsWithChildren) {
  const searchParams = useSearchParams();
  const pathname = usePathname();

  const [returnTo, setReturnTo] = useState<Page>();
  const [previousPages, setPreviousPages] = useState<Page[]>([]);

  const sameResource = (path1: string, path2: string) => {
    const pathParts1 = path1.split("/");
    const pathParts2 = path2.split("/");

    const compareString1 = pathParts1.slice(0, 2).join("/");
    const compareString2 = pathParts2.slice(0, 2).join("/");

    return compareString1 === compareString2;
  };

  useEffect(() => {
    if (pathname && returnTo) {
      // If we've gone back up the stack then queue up the next returnTo
      if (pathname === returnTo.returnTo) {
        setReturnTo(previousPages.pop());
      } else if (!sameResource(pathname, returnTo.pathname)) {
        setReturnTo(undefined);
        setPreviousPages([]);
      }
    }
  }, [pathname]);

  useEffect(() => {
    if (searchParams?.has("returnTo")) {
      const newReturnTo = searchParams.get("returnTo")!;

      if (returnTo) {
        setPreviousPages([...previousPages, returnTo]);
      }

      setReturnTo({
        pathname: pathname!,
        returnTo: newReturnTo,
      });
    }
  }, [searchParams?.toString()]);

  const contextValue = useMemo(
    () => ({
      returnTo,
      previousPages,
    }),
    [returnTo, previousPages]
  );

  return (
    <ReturnContext.Provider value={contextValue}>
      {children}
    </ReturnContext.Provider>
  );
}
