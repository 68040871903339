import Kitsu from "kitsu";
import mapValues from "lodash/mapValues";
import pickBy from "lodash/pickBy";

type JsonApiHeaders = { [key: string]: string };

export interface JsonApiParams {
  fields?: { [key: string]: string[] | string | undefined };
  include?: string[] | string;
  filter?: { [key: string]: string } | FilterParams;
  sort?: string;
  page?: {
    page?: number;
    per_page: number;
  };
}

interface JsonApiRequestParams {
  fields?: { [key: string]: string };
  include?: string;
  filter?: { [key: string]: string } | FilterParams;
  sort?: string;
  page?: {
    page?: number;
    per_page: number;
  };
}

export interface JsonApiResponse<T> {
  data: T;
  headers: { [key: string]: string };
  meta?: {
    [key: string]: any;
    page?: number;
    per_page?: number;
    total_pages?: number;
    total_count?: number;
  };
}

interface JsonApiOptions {
  accessToken: string;
  currentOrganisationId?: string;
  baseUrl?: string | null;
}

interface JsonApiRequestOptions {
  params?: JsonApiParams;
  headers?: any;
  axiosOptions?: any;
}

export interface FilterParams {
  [key: string]: any;
  property_id?: string;
  portfolio_id?: string;
  user_id?: string;
  date?: string;
  status?: string;
  state?: string;
  source_method?: string;
}

const formatParamsValue = (value: string | string[]) => {
  if (!value || typeof value === "string") {
    return value;
  }

  return value.join(",");
};

const formatParams = (params?: JsonApiParams) => {
  if (!params) {
    return undefined;
  }

  const { fields, include, ...otherParams } = params;

  const filteredFields = pickBy(fields, (value) => Boolean(value)) as {
    [key: string]: string;
  };
  const formattedFields = mapValues(
    filteredFields,
    (value: string | string[]) => formatParamsValue(value)
  );
  const formattedInclude = include && formatParamsValue(include);

  const formattedParams: JsonApiRequestParams = {
    ...otherParams,
  };

  if (formattedFields && Object.keys(formattedFields).length) {
    formattedParams.fields = formattedFields;
  }

  if (formattedInclude) {
    formattedParams.include = formattedInclude;
  }

  return formattedParams;
};

export default class JsonApi {
  private kitsu: Kitsu;

  constructor({ accessToken, currentOrganisationId, baseUrl }: JsonApiOptions) {
    const headers: JsonApiHeaders = {
      Authorization: `Bearer ${accessToken}`,
    };
    if (currentOrganisationId) {
      headers["Organisation-Id"] = currentOrganisationId;
    }

    this.kitsu = new Kitsu({
      headers,
      baseURL: baseUrl ?? `${process.env.NEXT_PUBLIC_FOUNDATIONS_HOST}/v1`,
      pluralize: false,
      camelCaseTypes: false,
    });

    this.kitsu.interceptors.response.use((resp) => ({
      ...resp,
      headers: {
        ...resp.headers,
        "status-code": resp.status.toString(),
      },
    }));
  }

  get(path: string, options?: JsonApiRequestOptions) {
    const { params, ...otherOptions } = options || {};
    // Kitsu is strict on the page params here so we use any as our API uses page and per_page
    return this.kitsu.get(path, {
      params: formatParams(params),
      ...otherOptions,
    } as any);
  }

  post<T>(
    path: string,
    body: T | null = null,
    options?: JsonApiRequestOptions
  ) {
    const { params, ...otherOptions } = options || {};
    return this.kitsu.create(path, body, {
      params: formatParams(params),
      ...otherOptions,
    });
  }

  patch<T>(path: string, body: T, options?: JsonApiRequestOptions) {
    const { params, ...otherOptions } = options || {};
    return this.kitsu.update(path, body, {
      params: formatParams(params),
      ...otherOptions,
    });
  }

  delete<T>(path: string, id: T, options?: JsonApiRequestOptions) {
    const { params, ...otherOptions } = options || {};
    return this.kitsu.remove(path, id as unknown as string, {
      params: formatParams(params),
      ...otherOptions,
    });
  }

  request<T>(
    path: string,
    type: string,
    body: T | null = null,
    method?: string,
    options?: JsonApiRequestOptions
  ) {
    const { params, ...otherOptions } = options || {};
    return this.kitsu.request({
      url: path,
      body,
      method,
      type,
      params: formatParams(params),
      ...otherOptions,
    });
  }
}
