import Zendesk from "@residently/react-zendesk";

import I18n from "services/I18n";

function ZendeskChat() {
  const zendeskKey = process.env.NEXT_PUBLIC_ZENDESK_KEY;

  if (!zendeskKey) {
    return null;
  }

  return (
    <Zendesk
      zendeskKey={zendeskKey}
      chat={{
        departments: {
          enabled: [],
        },
        prechatForm: {
          greeting: {
            "*": I18n.t("components.zendeskChat.greeting"),
          },
        },
      }}
    />
  );
}

export default ZendeskChat;
