import { Analytics, getAnalytics, setUserProperties } from "firebase/analytics";
import { FirebaseApp, initializeApp } from "firebase/app";
import { createContext, useEffect, useState } from "react";

import useAuth from "hooks/useAuth";

const firebaseConfig = {
  apiKey: process.env.NEXT_PUBLIC_FIREBASE_API_KEY,
  authDomain: process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.NEXT_PUBLIC_FIREBASE_DATABASE_URL,
  projectId: process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID,
  storageBucket: process.env.NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.NEXT_PUBLIC_FIREBASE_SENDER_ID,
  appId: process.env.NEXT_PUBLIC_FIREBASE_APP_ID,
  measurementId: process.env.NEXT_PUBLIC_FIREBASE_MEASUREMENT_ID,
};

interface FirebaseContextProps {
  analytics?: Analytics | null;
  app?: FirebaseApp | null;
}

interface Props {
  children: any;
}

export const FirebaseContext = createContext<FirebaseContextProps>({});
const firebaseApp = initializeApp(firebaseConfig);

function FirebaseProvider({ children }: Props) {
  const { email, organisationId, userId } = useAuth();
  const [firebaseValue, setFirebaseValue] = useState<FirebaseContextProps>({
    app: null,
    analytics: null,
  });

  const [analytics, setAnalytics] = useState<Analytics | null>(null);

  useEffect(() => {
    if (!analytics) {
      setAnalytics(getAnalytics());
      return;
    }

    setUserProperties(analytics, {
      service: process.env.NEXT_PUBLIC_NEIGHBOURHOOD_HOST,
    });

    setFirebaseValue({
      app: firebaseApp,
      analytics,
    });
  }, [analytics]);

  useEffect(() => {
    if (!email || !analytics) {
      return;
    }

    const isStaff =
      email?.endsWith("@residently.com") ||
      email?.endsWith("@resident.ly") ||
      email?.startsWith("rdly.in.mail");

    setUserProperties(analytics, {
      service: process.env.NEXT_PUBLIC_NEIGHBOURHOOD_HOST,
      user_id: userId,
      org_id: organisationId,
      type: isStaff ? "staff" : "partner",
    });
  }, [email, analytics]);

  return (
    <FirebaseContext.Provider value={firebaseValue}>
      {children}
    </FirebaseContext.Provider>
  );
}

export default FirebaseProvider;
