import { useRouter } from "next/router";
import { useEffect, useRef } from "react";

const usePreserveScroll = () => {
  const router = useRouter();
  const scrollPositions = useRef<{ [url: string]: number }>({});

  useEffect(() => {
    // The delay is introduced to account for potential double rendering.
    // During client-side navigation, components may render twice.
    // The delay ensures that scroll restoration occurs after any potential double rendering.
    const delay = 100;

    const onRouteChangeStart = () => {
      const url = router.asPath;
      scrollPositions.current[url] = window.scrollY;
    };

    const onRouteChangeComplete = (url: string) => {
      setTimeout(() => {
        if (scrollPositions.current[url] !== undefined) {
          window.scroll({
            top: scrollPositions.current[url],
            behavior: "auto",
          });
        }
      }, delay);
    };

    router.events.on("routeChangeStart", onRouteChangeStart);
    router.events.on("routeChangeComplete", onRouteChangeComplete);

    // Initial scroll restoration
    if (scrollPositions.current[router.asPath] !== undefined) {
      window.scroll({
        top: scrollPositions.current[router.asPath],
        behavior: "auto",
      });
    }

    return () => {
      router.events.off("routeChangeStart", onRouteChangeStart);
      router.events.off("routeChangeComplete", onRouteChangeComplete);
    };
  }, [router]);
};

export default usePreserveScroll;
