import JsonApi, { JsonApiParams } from "services/JsonApi";
import { PrePaperworkUserDetails, UserParams } from "services/users/types";

export const initialsFromName = (name?: string) => {
  if (!name) {
    return "";
  }

  const initials = name.replace(/[^A-Z]/g, "");

  if (
    initials.length > 0 &&
    initials.length !== name.replace(/\s/g, "").length
  ) {
    return initials;
  }

  const nameParts = name.split(/[\s-]+/);

  if (nameParts && nameParts.length > 0) {
    return nameParts.map((e) => e.charAt(0).toUpperCase()).join();
  }

  return initials;
};

export const residentsFromOfferDetails = (
  prePaperworkUsers: PrePaperworkUserDetails[] | undefined
) =>
  prePaperworkUsers?.filter(
    (prePaperworkUser) => prePaperworkUser.user_type !== "guarantor"
  );

export const guarantorsFromOfferDetails = (
  prePaperworkUsers: PrePaperworkUserDetails[] | undefined
) =>
  prePaperworkUsers?.filter(
    (prePaperworkUser) => prePaperworkUser.user_type === "guarantor"
  );

export const updateUser = async (
  accessToken: string,
  user: UserParams,
  params: JsonApiParams
) => {
  const api = new JsonApi({ accessToken });

  const { data } = await api.patch("users", user, { params });

  return data;
};
