import { Auth0Provider } from "@auth0/auth0-react";
import { ThemeProvider } from "@mui/material/styles";
import { PartnerTheme } from "@residently/components/themes";
import { ErrorBoundary } from "@sentry/nextjs";
import { AppContext, AppProps } from "next/app";
import { SWRConfig } from "swr";

import "pages/_app.css";

import { CurrentOrganisationIdProvider } from "context/CurrentOrganisationIdContext";
import { GlobalTemporaryAlertContextProvider } from "context/GlobalTemporaryAlertContext";
import { ReturnContextProvider } from "context/ReturnContext";

import FirebaseProvider from "components/providers/FirebaseProvider";

import Hotjar from "components/molecules/Hotjar";
import ZendeskChat from "components/molecules/ZendeskChat";

import usePreserveScroll from "hooks/usePreserveScroll";

import I18n from "services/I18n";

export default function MyApp({ Component, pageProps }: AppProps) {
  usePreserveScroll();

  return (
    <ErrorBoundary fallback={<p>{I18n.t("components.navigation.error")}</p>}>
      <Auth0Provider
        domain={process.env.NEXT_PUBLIC_AUTH0_DOMAIN || ""}
        clientId={process.env.NEXT_PUBLIC_AUTH0_CLIENT_ID || ""}
        authorizationParams={{
          redirect_uri: process.env.NEXT_PUBLIC_AUTH0_REDIRECT_URI,
          audience: process.env.NEXT_PUBLIC_AUTH0_AUDIENCE,
        }}
      >
        <FirebaseProvider>
          <ThemeProvider theme={PartnerTheme}>
            <SWRConfig value={{ revalidateOnFocus: false }}>
              <CurrentOrganisationIdProvider>
                <GlobalTemporaryAlertContextProvider>
                  <ReturnContextProvider>
                    <Component {...pageProps} />
                  </ReturnContextProvider>
                </GlobalTemporaryAlertContextProvider>
              </CurrentOrganisationIdProvider>
              <ZendeskChat />
              <Hotjar />
            </SWRConfig>
          </ThemeProvider>
        </FirebaseProvider>
      </Auth0Provider>
    </ErrorBoundary>
  );
}

MyApp.getInitialProps = async ({ Component, ctx }: AppContext) => {
  if (!Component.getInitialProps) {
    return { pageProps: {} };
  }

  const pageProps = await Component.getInitialProps(ctx);
  return { pageProps };
};
